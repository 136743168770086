import { Route } from '@angular/router';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { AuthGuard } from '@core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { Page404Component } from './screens/authentication/page404/page404.component';
import { IsAdminGuard } from './core/guard/Admin.guard';
import { ProjectCreationComponent } from './screens/project-creation/project-creation.component';

export const APP_ROUTE: Route[] = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },

      {
        path: 'dashboard',
        loadChildren: () =>
          import('./screens//dashboard/dashboard.routes').then(
            (m) => m.DASHBOARD_ROUTE
          ),
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./screens/clients/clients.routes').then(
            (m) => m.CLIENTS_ROUTE
          ),
      },
      {
        path: 'commands',
        loadChildren: () =>
          import('./screens/commands/commands.routes').then(
            (m) => m.COMMANDS_ROUTE
          ),
      },
      {
        path: 'providers',
        loadChildren: () =>
          import('./screens/providers/providers.routes').then(
            (m) => m.PROVIDERS_ROUTE
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./screens/products/products.routes').then(
            (m) => m.PRODUCTS_ROUTE
          ),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./screens/accounts/accounts.routes').then(
            (m) => m.ACCOUCTS_ROUTE),
      },
      {
        path: 'emails',
        loadChildren: () =>
          import('./screens/emails/emails.routes').then(
            (m) => m.EMAILS_ROUTE),
      },
      {
        path: 'finances',
        canActivate: [IsAdminGuard],
        loadChildren: () =>
          import('./screens/finances/finances.routes').then((m) => m.FINANCES_ROUTE),
      },
      {
        path: 'project-creation',
        canActivate: [IsAdminGuard],
        loadChildren:()=> 
        import('./screens/project-creation/project-creation.routes').then((m)=>m.PROJECTCREATION_ROUTE),
      },
      {
        path: 'alerts',
        loadChildren: () =>
          import('./screens/alerts-execution-logs/alerts-execution-logs.routes').then(
            (m) => m.ALERTSEXECUTIONLOGS_ROUTE
          ),
      },
      {
        path: 'sms',
        loadChildren: () =>
          import('./screens/sms-templates/sms-templates.routes').then(
            (m) => m.SMSTEMPLATES_ROUTE
          ),
      },
      {
        path: 'email',
        loadChildren: () =>
          import('./screens/email-templates/email-templates.routes').then(
            (m) => m.EMAILTEMPLATES_ROUTE
          ),
      },
      {
        path: 'transaction',
        loadChildren: () =>
          import('./screens/transaction-logs/transaction-logs.routes').then(
            (m) => m.TRANSACTIONLOGS_ROUTE
          ),
      },
      {
        path: 'tags',
        loadChildren: () =>
          import('./screens/tags/tags-list/tags-list.routes').then(
            (m) => m.TAGSMAKERS_ROUTE
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./screens/authentication/auth.routes').then((m) => m.AUTH_ROUTE),
  },
  { path: 'page-not-found', component: Page404Component },
  { path: '**', redirectTo: 'page-not-found' },
];
