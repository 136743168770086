<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header">
      <a
        href="#"
        onClick="return false;"
        class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"
      ></a>
      <!-- mobile menu bars -->
      <a
        href="#"
        onClick="return false;"
        class="bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
      ></a>
      <a class="navbar-brand" routerLink="dashboard">
        <img src="assets/images/hubscans-main-logo.png" alt="" class="headerLogo" />
      </a>
    </div>
    <div
      class="collapse navbar-collapse"
      [ngClass]="isNavbarCollapsed ? '' : 'show'"
    >
      <ul class="pull-left collapse-menu-icon">
        <li class="menuBtn">
          <button
            mat-button
            (click)="callSidemenuCollapse()"
            class="sidemenu-collapse nav-notification-icons"
          >
            <app-feather-icons
              [icon]="'menu'"
              [class]="'header-icon'"
            ></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <!-- <li class="fullscreen">
          <button
            mat-button
            class="nav-notification-icons"
            (click)="callFullscreen()"
          >
            <app-feather-icons
              [icon]="'maximize'"
              [class]="'header-icon'"
            ></app-feather-icons>
          </button>
        </li> -->
        <!-- #END# Full Screen Button -->
        <!-- #START# Language selection-->
        <!-- <li class="nav-item">
          <button
            mat-button
            [matMenuTriggerFor]="languagemenu"
            class="lang-dropdown nav-notification-icons"
          >
            @if (flagvalue !== undefined) {
            <img src="{{ flagvalue }}" height="16" />
            } @if (flagvalue === undefined) {
            <img src="{{ defaultFlag }}" height="16" />
            }
          </button>
          <mat-menu #languagemenu="matMenu" class="lang-item-menu">
            @for (item of listLang; track item) {
            <div class="lang-item">
              <button
                mat-menu-item
                class="dropdown-item lang-item-list"
                (click)="setLanguage(item.text, item.lang, item.flag)"
                [ngClass]="{ active: langStoreValue === item.lang }"
              >
                <img src="{{ item.flag }}" class="flag-img" height="12" />
                <span class="align-middle">{{ item.text }}</span>
              </button>
            </div>
            }
          </mat-menu>
        </li> -->
        <!-- #END# Language selection-->
        <!-- #START# Notifications-->
         <li class="nav-item">
          <button
          (click)="navigateToCarto()"
                class="btn btn-success"
              >
                <span class="align-middle">Cartographie</span>
              </button>
         </li>
        <li class="nav-item" ngbDropdown>
          <button
            mat-button
            [matMenuTriggerFor]="notificationMenu"
            class="nav-notification-icons"
          >
            <app-feather-icons
              [icon]="'bell'"
              [class]="'header-icon'"
            ></app-feather-icons>
            <span class="label-count bg-orange"></span>
          </button>
          <mat-menu #notificationMenu="matMenu" class="nfc-menu">
            <div class="nfc-header">
              <h5 class="mb-0">Notitications</h5>
            </div>
            <div class="nfc-dropdown">
              <ng-scrollbar style="height: 360px" visibility="hover">
                <div class="noti-list header-menu">
                  <div class="menu">
                    @if (notifications$ | async ; as notifications) {
                    <div>
                      @for (notification of notifications; track
                      notification.id) {
                      <button
                        style="height: 120px"
                        mat-menu-item
                        (click)="markToRead(notification.id)"
                        [ngClass]="[notification.status]"
                        [routerLink]="notification.targetUrl !== '' ? notification.targetUrl : '/dashboard'"
                      >
                        <span class="table-img msg-user">
                          <i
                            class="material-icons-outlined nfc-type-icon"
                            [ngClass]="[notification.color]"
                            >{{ notification.icon }}</i
                          >
                        </span>
                        <span class="menu-info">
                          <span class="menu-title">{{
                            notification.title
                          }}</span>
                          <span class="menu-desc mt-2">
                            {{ notification.message }}
                          </span>
                          <span class="menu-desc">
                            <i class="material-icons">access_time</i>
                            {{ notification.time }}
                          </span>
                        </span>
                        <span
                          class="nfc-close"
                          (click)="removeNotification(notification.id)"
                        >
                          <app-feather-icons
                            [icon]="'x'"
                            [class]="'user-menu-icons'"
                          ></app-feather-icons>
                        </span>
                      </button>
                      }
                    </div>
                    }
                  </div>
                </div>
              </ng-scrollbar>
            </div>
            <!-- <div class="nfc-footer">
              <a class="nfc-read-all">Lire toutes les notifications</a>
            </div> -->
          </mat-menu>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="">
              <img
                src="assets/images/user.jpg"
                class="rounded-circle"
                width="32"
                height="32"
                alt="User"
              />
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button mat-menu-item class="user-item-list">
                    <app-feather-icons
                      [icon]="'user'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Compte
                  </button>
                  <!-- <button mat-menu-item class="user-item-list">
                    <app-feather-icons
                      [icon]="'mail'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Inbox
                  </button>
                  <button mat-menu-item class="user-item-list">
                    <app-feather-icons
                      [icon]="'settings'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Settings
                  </button> -->
                  <button
                    mat-menu-item
                    (click)="logout()"
                    class="user-item-list"
                  >
                    <app-feather-icons
                      [icon]="'log-out'"
                      [class]="'user-menu-icons'"
                    ></app-feather-icons
                    >Déconnexion
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
