<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-lg-4">
      <div class="auth-content p-4 d-flex align-items-center min-vh-100">
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div>
                <div class="text-center">
                  <div>
                    <a routerLink="" class="logo-full"
                      ><img src="assets/images/hubscans-main-logo.png" alt="logo-full"
                    /></a>
                  </div>
                  <h4 class="font-20 mt-4">Bienvenue !</h4>
                  <p class="text-muted">
                    Connectez-vous pour accéder à Hubscans.
                  </p>
                </div>
                <div class="p-2 mt-5">
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    @if (error) {
                    <div class="alert alert-danger mb-3 mt-0">{{ error }}</div>
                    }
                    <div class="form-group auth-form-group mb-4">
                      <mat-icon
                        matSuffix
                        class="material-icons-outlined auth-page-icon"
                        >email</mat-icon
                      >
                      <input
                        type="email"
                        formControlName="email"
                        class="form-control auth-control"
                        id="email"
                        placeholder="Email"
                        [ngClass]="{
                          'is-invalid': submitted && form['email'].errors
                        }"
                      />
                      @if (submitted && form['email'].errors) {
                      <div class="invalid-feedback">
                        @if (form['email'].errors['required']) {
                        <div>L'email est obligatoire</div>
                        } @if (form['email'].errors['email']) {
                        <div>L'email n'est pas valide</div>
                        }
                      </div>
                      }
                    </div>
                    <div class="form-group auth-form-group mb-4">
                      <mat-icon
                        matSuffix
                        class="material-icons-outlined auth-page-icon"
                        >vpn_key</mat-icon
                      >
                      <input
                        type="password"
                        formControlName="password"
                        class="form-control auth-control"
                        id="password"
                        [ngClass]="{
                          'is-invalid': submitted && form['password'].errors
                        }"
                        placeholder="Password"
                      />
                      @if (submitted && form['password'].errors) {
                      <div class="invalid-feedback">
                        @if (form['password'].errors['required']) {
                        <div>Le mot de passe est obligatoire</div>
                        }
                      </div>
                      }
                    </div>
                    <div class="flex-sb-m w-full p-t-15 p-b-20">
                      <!-- <div class="form-check m-0">
                        <label class="form-check-label">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                          />
                          Remember me
                          <span class="form-check-sign">
                            <span class="check"></span>
                          </span>
                        </label>
                      </div> -->
                      <div>
                        <a
                          class="txt1"
                          routerLink="/authentication/forgot-password"
                          >Mot de passe oublié ?</a
                        >
                      </div>
                    </div>
                    <div class="container-login100-form-btn">
                      <button
                        mat-raised-button
                        color="primary login"
                        class="login100-form-btn"
                        type="submit"
                      >
                        Se connecter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="auth-bg">
        <div class="bg-overlay"></div>
      </div>
    </div>
  </div>
</div>
